import { useEffect, useState } from "react";
import { useDownloader, specific, MIME_TYPES } from 'react-files-hooks';
import PuffLoader from "react-spinners/PuffLoader";
import PoetHex from "./PoetHex";
import { utils, ethers } from "ethers";

function bin2String(array) {
  var result = "";
  for (var i = 0; i < array.length; i++) {
    result += String.fromCharCode(array[i]);
  }
  return result;
}

function extractHiddenMessage(data) {
  var start = data.length-5;
  while (start > 0) {
    // 49 45 4E 44 == b'\x00IEND'
    if (
      data[start] == 0x00 &&
      data[start+1] == 0x49 &&
      data[start+2] == 0x45 &&
      data[start+3] == 0x4E &&
      data[start+4] == 0x44)
    {
      var encodedData = bin2String(data.slice(start+11, data.length));
      var message = atob(encodedData);
      return message;
    }
    start--;
  }
}

export default function PoetStatus(props) {
  const token = props.token;

  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(true);
  const [imageURL, setImageURL] = useState(null);
  const [hiddenMessage, setHiddenMessage] = useState("");

  useEffect(() => {

    const fetchImageData = async () => {
      try {
        const response = await fetch(imageURL);
        const content = await response.arrayBuffer();
        const bytes = new Uint8Array(content);
        const hiddenMessage = extractHiddenMessage(bytes);
        if (hiddenMessage) {
          setHiddenMessage("\"" + hiddenMessage + "\"");
        }
        setTimeout(function() {
          setLoading(false);
        }, 500);
      } catch (error) {
        setLoading(false);
        console.log("error", error);
      }
    };
    if (imageURL) {
      console.log("Fetching " + imageURL)
      fetchImageData();
    }

  }, [imageURL]);

  useEffect(() => {
    const fetchMetaData = async () => {
      try {
        const response = await fetch("https://lostpoets.api.manifoldxyz.dev/metadata/" + props.token);
        const json = await response.json();
        // "https://d1xxei964ioe0z.cloudfront.net/full/a161f1a5f415f4e14c76105c15b190e15071c89be025845aad5bab3b25ebaccd.png" is mapped to
        // "https://api.poetinfo.xyz/full/a161f1a5f415f4e14c76105c15b190e15071c89be025845aad5bab3b25ebaccd.png"
        // "https://ipfs.io/ipfs/QmaLSPgGa8EbrYJzbQzHidXrZqSRek6rYUEhniEah4rtL8"

        const imageURL = new URL(json.image_url)
        const host = imageURL.hostname

        if (host.includes("cloudfront.net")) {
          const filename = json.image_url.substring(json.image_url.lastIndexOf("/") + 1);
          const proxyPath = "https://api.poetinfo.xyz/full/" + filename;
          setImageURL(proxyPath)
        } else {
          setImageURL(json.image_url)
        }
      } catch (error) {
        setLoading(false);
        setErrorMessage("Lost Poet");
        console.log("error", error);
      }
    };
    fetchMetaData();
  }, [props.token]);

  return (
    <div className="poet-status">
      <div className="loading-box">
        {imageURL && <PoetHex url={imageURL} token={token} />}
        <PuffLoader size="150px" color="white" loading={loading} />
      </div>
      <div className="poet-number">{token < 1025 ? "Origin" : "Poet"} #{token.toString()}</div>
      <div className="hidden-message">{hiddenMessage}&nbsp;</div>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      {successMessage && <p className="success-message">{successMessage}</p>}
    </div>
  );
}
