import "./styles.css";

import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import Main from "./pages/Main";
import Map from "./pages/Map";
import MosaicGallery from "./pages/MosaicGallery";

//importing media assets
export default function App() {


  return (
    <Router>
      <div className="App">
        <div className="content">
          <>
            <div className="swapper">
              <Switch>
                <Route path="/" exact component={Main} />
                <Route path="/map" component={Map} />
                <Route path="/gallery/:id" component={MosaicGallery} />
              </Switch>
              <ul className="links">
              <li>
                <a href="/">[ Poet Lookup ]</a>
              </li>
                <li>
                  <a href="/gallery/tower">[ Mosaic ]</a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/fantastic_sci"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Made with curiosity by SciFantastic
                  </a>
                </li>
              </ul>
            </div>
          </>
        </div>
      </div>
    </Router>
  );
}
