
export default function PoetHex(props) {

  const url = props.url;
  const patternId = "pattern" + props.token;
  const patternRef = "url(#pattern" + props.token + ')';

  return (
    <svg width="150" height="150" viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <pattern id={patternId} patternUnits="userSpaceOnUse" width="100" height="100">
          <image href={url} width="100" height="100" />
        </pattern>
      </defs>
      <polygon points="50 0 95 25 95 75 50 100 5 75 5 25" fill={patternRef} />
    </svg>
  )
}
