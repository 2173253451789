import { Fragment, useState, useEffect } from "react";
import Papa from 'papaparse';

export default function Map(props) {
  const [poets, setPoets] = useState([]);

  useEffect(() => {
    async function getData() {
      const response = await fetch('/data/data.csv');
      const reader = response.body.getReader();
      const result = await reader.read(); // raw array
      const decoder = new TextDecoder('utf-8');
      const csv = decoder.decode(result.value); // the csv text
      const results = Papa.parse(csv, { header: true }); // object with { data, errors, meta }
      const poets = results.data;
      window.poets = poets;
      setPoets(poets);
    }
    getData()
  }, []);

  const thumbs = poets.map((poet) => {
    const token = parseInt(poet.token);
    const order = token-1; // TODO Prime ordering
    const styles = {
      left: (order%256)*4,    // computed based on child and parent's height
      top: Math.trunc(order/256)*4,  // computed based on child and parent's width
    };
    return (
      <img className="pt" style={styles} key={poet.token} src={"/thumbnails_8/" + poet.token + ".png"} />
    )
  });

  return (
    <div>
      <div className="title">
        <h1>Poet Map</h1>
      </div>

      <div className="poet-map">
        {thumbs}
      </div>

      <div className="instructions">
        <p>
          Explore the space of poets. Is there an order?
        </p>
      </div>
    </div>
  );
}
