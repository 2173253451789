import PoetStatus from "./PoetStatus";

export default function PoetList(props) {
  const tokens = props.tokens;
  const listItems = tokens.map((token) => (
    <li key={token.toString()}>
      <PoetStatus token={token} />
    </li>
  ));
  return <ul className="poet-list">{listItems}</ul>;
}
