import { Fragment, useState, useEffect } from "react";
import { MultiViewer } from 'react-iiif-viewer'
import { useParams, useHistory } from "react-router-dom";


export default function MosaicGallery(props) {

  const mosaics = [
    {
      id: 'tower',
      description: 'Lost Poets arranged to make a mosaic of <a href="https://en.wikipedia.org/wiki/The_Tower_of_Babel_(Bruegel)">Bruegel\'s <i>The (Great) Tower of Babel</i></a>.'
    },
    {
      id: 'latents',
      description: 'Poets sharing the same latent attribute arranged into columns.'
    },
    {
      id: 'prime',
      description: 'Poets arranged by their "Secret" prime number into a 256x256 grid'
    },
    {
      id: 'origin',
      description: 'Poets arranged by column for each of the 1024 origins.'
    },
  ]

  let { id } = useParams();

  const [mosaicIndex, setMosaicIndex] = useState(mosaics.findIndex((elem) => elem.id == id));

  let history = useHistory();

  function onIndexChange(newIndex) {
    setMosaicIndex(newIndex)
    history.push('/gallery/' + mosaics[newIndex].id);
  }

  return (
    <div>
      <MultiViewer
          height="600px"
          width="100%"
          iiifUrls={mosaics.map(m => "/mosaics/" + m.id + "/info.json")}
          currentIndexChanged={onIndexChange}
          currentIndex={mosaicIndex}
        />
      { <div className="mosaic-description" dangerouslySetInnerHTML={{__html: mosaics[mosaicIndex].description}} />  }
    </div>
  );

}
