import { Fragment, useState, useEffect } from "react";
import PoetList from "../PoetList";

export default function Main(props) {

  const [errorMessage, setErrorMessage] = useState(null);
  const [tokenInput, setTokenInput] = useState("");
  const [searches, setSearches] = useState([1]);

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("user"));
    if (data) {
      setDarkTheme(data.theme);
    }
  }, []);

  function handleFind(ignoreExisting=false) {
    console.log("handle find")
    const _token = parseInt(tokenInput);
    if (ignoreExisting == false && searches.includes(_token)) {
      setErrorMessage("Token already checked.")
    }
    if (_token && _token > 0 && _token < 66560 && !searches.includes(_token)) {
      setErrorMessage(null);
      setSearches([_token].concat(searches));
    } else {
      console.log("hmmm");
    }
  }

  function handleTokenInput(event) {
    setTokenInput(event.target.value);
  }

  function handleKeyDown(event) {
    if (event.key === 'Enter') {
      handleFind();
    }
  }

  function handleBlur(event) {
    console.log("handleBlur")
    handleFind(true);
  }

  //rendering form
  const renderSearchForm = (
    <div className="input">
      <input
        type="number"
        name="tokenId"
        placeholder="Poet Number"
        // value={tokenId}
        onChange={handleTokenInput}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
      />
      <p className="error-message">{errorMessage}</p>
    </div>
  );

  //rendering form
  const renderForm = (
    <Fragment>
      {renderSearchForm}
    </Fragment>
  );

  return (
    <div>
      <div className="title">
        <h1>Poet Secrets</h1>
      </div>

      <div className="instructions">
        <p>
          <a href="https://lostpoets.xyz/">Lost Poets</a> may be hiding some important information.
        </p>
      </div>
      <div className="block">
        <div className="form">{renderForm}</div>
      </div>
      <div className="results">
        <PoetList tokens={searches} />
      </div>
    </div>
  );
}
